import { AppBar, TextField } from '@mui/material'
import styled from 'styled-components'
import AccountMenu from '../AccountMenu/AccountMenu'
export const SidebarMain = styled.div`
/* .MuiInputBase-input
{
padding: 10px;
} */
.MuiAvatar-root
{
    background-color:var(--black) !important;
    width: 50px;
    height: 50px;
}
.css-k8255l-MuiInputBase-root-MuiInput-root:after
{
    border-bottom:0px;
}
.search
{
    background-color: var(--search-backgroud);
border: 1px solidvar(--search-backgroud) !important;
outline: none;
width: 100% !important;
padding: 5px;
border-radius:5px;
border-bottom: 0px;
}
.MuiInputBase-root:before {
    border-bottom: 0px;
}
.MuiInputBase-root:hover:not(.Mui-disabled):before 
{
    border-bottom: 0px !important;
    outline: none;
}
.MuiInputBase-root:after
{
    border-bottom:none !important;
}
/* .MuiToolbar-gutters

{
padding-left: 0px;
padding-right:0px;
} */
.MuiIconButton-root 
{
padding: 5px;
}
/* .MuiToolbar-root
{
    padding-left: 10px !important;
} */
.MuiDrawer-paper
{
/* color: var(--white) !important; */
background-color: var(--black) !important;
box-shadow: none;
overflow-y:auto;
}
/* Change start*/
.MuiCollapse-root{
min-height:fit-content !important;
}
/* Change end*/
.app_bar_right_content
{

}

.drawerMain .MuiTypography-root 
{
    color: var(--white) !important;
}
.drawerMain .MuiSvgIcon-root 
{
    color: var(--white) !important;
}
.menuIcon .MuiSvgIcon-root
{
    color: var(--light-button) !important;
    font-size: 40px !important;
}

@media only screen and (max-width: 991.5px) {
    .drawerheader
{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.drawerheader img
{
    width: 50px !important;
}

}

`
export const Search = styled(TextField)`

.MuiInputBase-input
{
    padding: 8.5px 14px;
    min-height: 1.4375em !important;
    height: 1.4375em !important;
}
`
