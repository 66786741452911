import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
// import Login from './pages/Login/Login'
// import Dashboard from './pages/Dashboard/Dashboard'
import { PrivateRoute } from './components/Routing'
import asynComponent from './AsyncComponent'
// import Support from './Support/Support';
// import CreateCategory from './pages/CreateCategory/CreateCategory'
// import CreateLevel from './pages/CreateLevel/CreateLevel'
// import LevelList from './pages/CreateLevel/LevelList'
// import CreateLab from './pages/CreateLab/CreateLab'
// import CreateUser from './pages/CreateUser/CreateUser'
// import CategoryList from './pages/CreateCategory/CategoryList'
// import AllUsers from './pages/AllUsers/AllUsers';
// import EditLab from './pages/EditLab/EditLab'
// import EditCategory from './pages/EditCategory/EditCategory'
// import EditLevel from './pages/EditLevel/EditLevel'
// import EditUser from './pages/EditUser/EditUser'
// import ViewLab from './pages/ViewLab/ViewLab'
// import AllLabs from './pages/AllLabs/AllLabs'
// import ViewUserDetails from "./pages/ViewUserDetail/ViewUserDetail"
// import Signup from './pages/SignUp/Signup'
// import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
// import EnforcePassword from './pages/EnforcePassword/EnforcePassword'
// import MFAVerification from './pages/MFAVerification/MFAVerification'
// import MFASetup from './pages/MFASetup/MFASetup'
// import MFASetupVerification from './pages/MFASetupVerification/MFASetupVerification'
// import LabGroup from './pages/LabGroup/LabGroup'
// import EditGroup from './pages/EditGroup/EditGroup'
// import CreateGroup from './pages/CreateGroup/CreateGroup'
// import AllGroups from './pages/AllGroups/AllGroups'
// import AllPolicies from './pages/AllPolicies/AllPolicies'
// import CreatePolicy from './pages/CreatePolicy/CreatePolicy'
// import EditPolicy from './pages/EditPolicy/EditPolicy'
// import AllAttachedPolicies from './pages/AllAttachedPolicies/AllAttachedPolicies'
// import AttachPolicy from './pages/AttachPolicy/AttachPolicy'
// import EditAttachedPolicy from './pages/EditAttachedPolicy/EditAttachedPolicy'
// import ViewPolicy from './pages/ViewPolicy/ViewPolicy'
// import AllUserGroups from './pages/AllUserGroups/AllUserGroups'
// import CreateUserGroup from './pages/CreateUserGroup/CreateUserGroup'
// import EditUserGroup from './pages/EditUserGroup/EditUserGroup'
// import GroupUser from './pages/GroupUser/GroupUser'
// import UserProfile from './pages/UserProfile/UserProfile'
// import PageNotFound from './pages/PageNotFound/PageNotFound'
// import MyCredit from './pages/MyCredit/MyCredit'
// import StripePayment from './pages/StripePayment/StripePayment'
// import CheckoutSuccess from './pages/CheckoutSuccess/CheckoutSuccess'
// import Transactions from './pages/Transactions/Transactions'
// import LabProvisionHistory from './pages/LabProvisionHistory/LabProvisionHistory'
// import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
// import RefundPolicy from './pages/RefundPolicy/RefundPolicy'
// import TermsOfService from './pages/TermsOfService/TermsOfService'
// import FilteredLabs from './pages/FilteredLabs/FilteredLabs'
// import FirstTimeLogin from './pages/FirstTimeLogin/FirstTimeLogin'
// import LiveUserList from './pages/LiveUserLIst/LiveUserList'


let Login = asynComponent(() => import('./pages/Login/Login'));
let Dashboard = asynComponent(() => import('./pages/Dashboard/Dashboard'));
let CreateUser = asynComponent(() => import('./pages/CreateUser/CreateUser'));
let AllUsers = asynComponent(() => import('./pages/AllUsers/AllUsers'));
let EditLab = asynComponent(() => import('./pages/EditLab/EditLab'));
let EditCategory = asynComponent(() => import('./pages/EditCategory/EditCategory'));
let EditLevel = asynComponent(() => import('./pages/EditLevel/EditLevel'));
let EditUser = asynComponent(() => import('./pages/EditUser/EditUser'));
let ViewLab = asynComponent(() => import('./pages/ViewLab/ViewLab'));

let ViewRating = asynComponent(() => import('./pages/ViewRating/ViewRating'));

// let AllLabs = asynComponent(() => import('./pages/AllLabs/AllLabs'));
let ViewUserDetails = asynComponent(() => import('./pages/ViewUserDetail/ViewUserDetail'));
let Signup = asynComponent(() => import('./pages/SignUp/Signup'));
let ForgotPassword = asynComponent(() => import('./pages/ForgotPassword/ForgotPassword'));
let EnforcePassword = asynComponent(() => import('./pages/EnforcePassword/EnforcePassword'));
let MFAVerification = asynComponent(() => import('./pages/MFAVerification/MFAVerification'));
let MFASetup = asynComponent(() => import('./pages/MFASetup/MFASetup'));
let MFASetupVerification = asynComponent(() => import('./pages/MFASetupVerification/MFASetupVerification'));
let LabGroup = asynComponent(() => import('./pages/LabGroup/LabGroup'));
let EditGroup = asynComponent(() => import('./pages/EditGroup/EditGroup'));
let AllPolicies = asynComponent(() => import('./pages/AllPolicies/AllPolicies'));
let CreatePolicy = asynComponent(() => import('./pages/CreatePolicy/CreatePolicy'));
let EditPolicy = asynComponent(() => import('./pages/EditPolicy/EditPolicy'));
let AttachPolicy = asynComponent(() => import('./pages/AttachPolicy/AttachPolicy'));
let AllAttachedPolicies = asynComponent(() => import('./pages/AllAttachedPolicies/AllAttachedPolicies'));
let EditAttachedPolicy = asynComponent(() => import('./pages/EditAttachedPolicy/EditAttachedPolicy'));
let ViewPolicy = asynComponent(() => import('./pages/ViewPolicy/ViewPolicy'));
let AllUserGroups = asynComponent(() => import('./pages/AllUserGroups/AllUserGroups'));
let CreateUserGroup = asynComponent(() => import('./pages/CreateUserGroup/CreateUserGroup'));
let EditUserGroup = asynComponent(() => import('./pages/EditUserGroup/EditUserGroup'));
let GroupUser = asynComponent(() => import('./pages/GroupUser/GroupUser'));
let UserProfile = asynComponent(() => import('./pages/UserProfile/UserProfile'));
let PageNotFound = asynComponent(() => import('./pages/PageNotFound/PageNotFound'));
let MyCredit = asynComponent(() => import('./pages/MyCredit/MyCredit'));
let StripePayment = asynComponent(() => import('./pages/StripePayment/StripePayment'));
let CheckoutSuccess = asynComponent(() => import('./pages/CheckoutSuccess/CheckoutSuccess'));
let Transactions = asynComponent(() => import('./pages/Transactions/Transactions'));
let LabProvisionHistory = asynComponent(() => import('./pages/LabProvisionHistory/LabProvisionHistory'));
let PrivacyPolicy = asynComponent(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
let RefundPolicy = asynComponent(() => import('./pages/RefundPolicy/RefundPolicy'));
let TermsOfService = asynComponent(() => import('./pages/TermsOfService/TermsOfService'));
let FilteredLabs = asynComponent(() => import('./pages/FilteredLabs/FilteredLabs'));
let FirstTimeLogin = asynComponent(() => import('./pages/FirstTimeLogin/FirstTimeLogin'));
let LiveUserList = asynComponent(() => import('./pages/LiveUserLIst/LiveUserList'));
let Support = asynComponent(() => import('./pages/Support/Support'));
let GenerateCoupon = asynComponent (()=>import ("./pages/GenerateCoupon/GenerateCoupon"))
let CouponList = asynComponent (()=>import("./pages/CouponList/CouponList"))
let CreateMultipleUsers = asynComponent(() => import('./pages/CreateMultipleUsers/CreateMultipleUsers'));




function Routes() {
	return (
		<Router>
			<Switch>
				<Route
					exact
					path='/'
					component={Login}
				/>
				<Route
					exact
					path='/login'
					component={Login}
				/>

				<Route
					exact
					path='/firsttimelogin'
					component={FirstTimeLogin}
				/>

				<Route
					exact
					path='/signup'
					component={Signup}
				/>
				<Route
					exact
					path="/forgotpassword"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/PageNotFound"
					component={PageNotFound}
				/>
				<Route
					exact
					path="/MFAVerification"
					component={MFAVerification}
				/>
				<Route
					exact
					path="/enforcePasswordChange"
					component={EnforcePassword}
				/>
				<Route
					exact
					path="/terms-of-service"
					component={TermsOfService}
				/>
				<Route
					exact
					path="/privacy-policy"
					component={PrivacyPolicy}
				/>
				<Route
					exact
					path="/refund-policy"
					component={RefundPolicy}
				/>
				<PrivateRoute
					exact
					path="/MFASetup"
					hasSidebar={false}
					component={MFASetup}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path="/MFASetupVerification/:id"
					hasSidebar={false}
					component={MFASetupVerification}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				{/* <NewSidebar> */}
				<PrivateRoute
					exact
					hasSidebar={true}
					path='/dashboard'
					component={Dashboard}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/userProfile'
					hasSidebar={true}
					component={UserProfile}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/myCredit'
					hasSidebar={true}
					component={MyCredit}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/generateCoupon'
					hasSidebar={true}
					component={GenerateCoupon}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/couponList'
					hasSidebar={true}
					component={CouponList}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/Transactions'
					hasSidebar={true}
					component={Transactions}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/Payment'
					hasSidebar={true}
					component={StripePayment}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/checkout-success'
					hasSidebar={false}
					component={CheckoutSuccess}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/labGroup/:id'
					hasSidebar={true}
					component={LabGroup}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/AllUser'
					component={AllUsers}
					hasSidebar={true}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/groupUsers/:id'
					hasSidebar={true}
					component={GroupUser}
					allowedRoles={["TenantAdmin"]}
				/>
				{/* <PrivateRoute 
						exact 
						path='/allGroups'
						hasSidebar={true}
						component={AllGroups} 
						allowedRoles={["TenantAdmin"]} 
					/> */}
				<PrivateRoute
					exact
					path='/allPolicies'
					hasSidebar={true}
					component={AllPolicies}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/attachPolicy'
					hasSidebar={true}
					component={AttachPolicy}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editAttachedPolicy/:id'
					hasSidebar={true}
					component={EditAttachedPolicy}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/allAttachedPolicies'
					hasSidebar={true}
					component={AllAttachedPolicies}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/allUserGroups'
					hasSidebar={true}
					component={AllUserGroups}
					allowedRoles={["TenantAdmin"]}
				/>
				{/* <PrivateRoute 
						exact 
						path='/createcategory'
						hasSidebar={true}
						component={CreateCategory} 
						allowedRoles={["TenantAdmin"]}
					/> */}
				<PrivateRoute
					exact
					path='/createpolicy'
					hasSidebar={true}
					component={CreatePolicy}
					allowedRoles={["TenantAdmin"]}
				/>
				{/* <PrivateRoute 
						exact 
						path='/category/AllCategory'
						hasSidebar={true}
						component={CategoryList}
						allowedRoles={["TenantAdmin"]}
					/> */}
				<PrivateRoute
					exact
					path='/createuser'
					hasSidebar={true}
					component={CreateUser}
					allowedRoles={["TenantAdmin"]}
				/>
					<PrivateRoute
					exact
					path='/createmultipleusers'
					hasSidebar={true}
					component={CreateMultipleUsers}
					allowedRoles={["TenantAdmin"]}
				/>
				{/* <PrivateRoute 
						exact 
						path='/level/AllLevels'
						hasSidebar={true}
						component={LevelList}
						allowedRoles={["TenantAdmin"]}
					/> */}
				{/* <PrivateRoute 
						exact 
						path='/level/CreateLevels'
						hasSidebar={true}
						component={CreateLevel}
						allowedRoles={["TenantAdmin"]}
					/> */}
				{/* <PrivateRoute 
						exact 
						path='/createlab'
						hasSidebar={true}
						component={CreateLab}
						allowedRoles={["TenantAdmin"]}
					/> */}
				<PrivateRoute
					exact
					path='/createUserGroup'
					hasSidebar={true}
					component={CreateUserGroup}
					allowedRoles={["TenantAdmin"]}
				/>			
				{/* <PrivateRoute 
						exact 
						path='/creategroup'
						hasSidebar={true}
						component={CreateGroup}
						allowedRoles={["TenantAdmin"]}
					/> */}
				<PrivateRoute
					exact
					path='/editlab/:id'
					hasSidebar={true}
					component={EditLab}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editUserGroup/:id'
					hasSidebar={true}
					component={EditUserGroup}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editcategory/:id'
					hasSidebar={true}
					component={EditCategory}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editpolicy/:id'
					hasSidebar={true}
					component={EditPolicy}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/viewPolicy/:id'
					hasSidebar={true}
					component={ViewPolicy}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editlevel/:id'
					hasSidebar={true}
					component={EditLevel}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/editGroup/:id'
					hasSidebar={true}
					component={EditGroup}
					allowedRoles={["TenantAdmin"]}
				/>
				{/* <PrivateRoute
					exact
					path='/alllabs'
					hasSidebar={true}
					component={AllLabs}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/> */}
				{/* <PrivateRoute
					exact
					path='/alllabs/:id'
					hasSidebar={true}
					component={AllLabs}
					allowedRoles={["TenantUser"]}
				/> */}
				<PrivateRoute
					exact
					path='/edituser'
					hasSidebar={true}
					component={EditUser}
				/>
				<PrivateRoute
					exact
					path='/viewuser/:id'
					hasSidebar={true}
					component={ViewUserDetails}
				/>
				<PrivateRoute
					exact
					path='/viewlab/:id'
					hasSidebar={true}
					component={ViewLab}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>

				<PrivateRoute
					exact
					path='/view-ratings/:id'
					hasSidebar={true}
					component={ViewRating}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>

				<PrivateRoute
					exact
					path='/labProvisionHistory'
					hasSidebar={true}
					component={LabProvisionHistory}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/filteredLabs'
					hasSidebar={true}
					component={FilteredLabs}
					allowedRoles={["TenantAdmin", "TenantUser"]}
				/>
				<PrivateRoute
					exact
					path='/monitoring/live-user-list'
					hasSidebar={true}
					component={LiveUserList}
					allowedRoles={["TenantAdmin"]}
				/>
				<PrivateRoute
					exact
					path='/support'
					hasSidebar={true}
					component={Support}
					allowedRoles={["TenantAdmin"]}
				/>
				<Route
					exact
					path='*'
					component={PageNotFound}
				/>
				{/* </NewSidebar> */}
			</Switch>
		</Router>
	)
}

export default Routes