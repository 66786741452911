import { LOGIN_FAIL, LOGIN_SUCCESS, SET_LOADER, SET_ACTION, LOGOUT_ACTION,SET_WHITE_LABLE } from '../types/authTypes'
import Cookies from 'js-cookie'

const initialState = {
	loading : false,
	isAuthenticated : Cookies.get('isAuthenticated'),
	userEmail : Cookies.get('email'),
	message : Cookies.get('message'),
	firstName : Cookies.get('firstName'),
	lastName : Cookies.get('lastName'),
	idToken : Cookies.get('idToken'),
	accessToken : Cookies.get('accessToken'),
	refreshToken : Cookies.get('refreshToken'),
	tokenType : Cookies.get('tokenType'),
	userRole : Cookies.get('userRole'),
	tenantName: Cookies.get('tenantName'),
	logo : Cookies.get('logo'),
	isTenantLogin: Cookies.get('isTenantLogin'),
}

const authReducer = (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case SET_LOADER:
			return { ...state, loading: payload }
		case LOGIN_SUCCESS:
			return { ...state, isTenantLogin: payload?.isTenantLogin, loading: false, isAuthenticated: true, userEmail : payload?.userEmail, message : payload?.message, firstName : payload?.firstName, lastName : payload?.lastName, idToken : payload?.IdToken,tokenType : payload?.TokenType, userRole :  payload?.userRole, errorMessage : {}}
		case SET_ACTION : 
			return { ...state, loading: false, caseType : payload?.action, isAuthenticated: false, session : payload?.session, userEmail : payload?.userEmail, message : payload?.message, errorMessage : {}}
		case LOGIN_FAIL:
			return { ...initialState, loading: false, isAuthenticated: false, errorMessage : payload?.err, userEmail : payload?.userEmail, session:payload?.session}
		case SET_WHITE_LABLE:
				return { ...initialState,tenantName : payload?.tenantName, logo:payload?.logo }
		case LOGOUT_ACTION :
			return {...payload}
		default:
			return state
	}
}

export default authReducer
