import Cookies from 'js-cookie'
import axios from 'axios'
import { setAlert } from './alertActions'
import { SET_LOADER, LOGIN_SUCCESS, LOGIN_FAIL, SET_ACTION, LOGOUT_ACTION, SET_WHITE_LABLE } from '../types/authTypes';
import { configuration } from '../../helper/appconfig';
import toastAlert from '../../helper/ToastAlert/ToastAlert';

//Login user
export const loginUser = (userEmail, password) => async (dispatch) => {
	dispatch({
		type: SET_LOADER,
		payload: true,
	})
	const body = { userEmail, password };
	const url = configuration.signIn;
	await axios
		.post(url, body)
		.then((response) => {
			console.log(response.data?.action, "ActionKey")
			if (response.data?.action) {
				dispatch({
					type: SET_ACTION,
					payload: { ...response.data, userEmail },
				})
				dispatch(setAlert(response?.data?.message, 'success'))
			} else {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: { ...response.data, userEmail },
				})
				//for normal user login only - case 1
				Cookies.set('accessToken', response.data?.AccessToken)
				Cookies.set('tokenType', response.data?.TokenType)
				Cookies.set('email', userEmail)
				Cookies.set('refreshToken', response.data?.RefreshToken)
				Cookies.set('idToken', response.data?.IdToken)
				Cookies.set('userRole', response.data?.userRole)
				Cookies.set('isTenantLogin', response.data?.isTenantLogin)
				Cookies.set('isAuthenticated', true)
				Cookies.set('firstName', response.data?.firstName)
				Cookies.set('lastName', response.data?.lastName)
				Cookies.set('message', response.data?.message)
				Cookies.set('MFAEnabled', false)
				toastAlert(response?.data?.message ? response?.data?.message : "Successfully logged in", 'success')
			}
			// this.props.histroy.push('/dashbord')
		})
		.catch((err) => {
			dispatch({
				type: LOGIN_FAIL,
				payload: { err }
			})
			toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.", 'error')
		})
}

export const tenantWhiteLabel = () => async (dispatch) => {
	const url = configuration.getWhiteLable;
	await axios
		.get(url)
		.then((response) => {
			console.log(response, "response from white lable api");
			dispatch({
				type: SET_WHITE_LABLE,
				payload: response.data.tenantInfo,
			})
			// toastAlert(response?.data?.message, 'success');
			Cookies.set('tenantName', response.data?.tenantInfo?.tenantName)
			Cookies.set('logo', response.data?.tenantInfo?.logo)
			document.title = response.data?.tenantInfo?.tenantName
			let link = document.querySelector("link[rel~='icon']");
			console.log(link, "link link link")
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}
			link.href = response.data?.tenantInfo?.logo;
		})
		.catch((err) => {
			if (!err.response) {
				toastAlert("Please check your internet connection.", "error")
			  }
			  else{
				toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.",'error')
			  }
				// toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.",'error')
		})
}
export const verifyMFA = (session, mfa_code, userEmail) => async (dispatch) => {
	dispatch({
		type: SET_LOADER,
		payload: true,
	})
	const body = { session, mfa_code, userEmail };
	const url = configuration.mfaVerification;
	await axios
		.post(url, body)
		.then((response) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { ...response.data, userEmail },
			})
			Cookies.set('accessToken', response.data?.AccessToken)
			Cookies.set('tokenType', response.data?.TokenType)
			Cookies.set('refreshToken', response.data?.RefreshToken)
			Cookies.set('idToken', response.data?.IdToken)
			Cookies.set('userRole', response.data?.userRole)
			Cookies.set('isTenantLogin', response.data?.isTenantLogin)
			Cookies.set('email', userEmail)
			Cookies.set('isAuthenticated', true)
			Cookies.set('firstName', response.data?.firstName)
			Cookies.set('lastName', response.data?.lastName)
			Cookies.set('message', response.data?.message)
			Cookies.set('MFAEnabled', true)
			toastAlert(response?.data?.message ? response?.data?.message : "MFA token verified successfully", 'success')
		})
		.catch((err) => {
			dispatch({
				type: LOGIN_FAIL,
				payload: { userEmail, err, session }
			})
			toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.", 'error')
		})
}

export const enforcePasswordChange = (session, new_password, userEmail) => async (dispatch) => {
	dispatch({
		type: SET_LOADER,
		payload: true,
	})
	const body = { session, userEmail, new_password };
	const url = configuration.enforcePasswordChange;
	await axios
		.post(url, body)
		.then((response) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { ...response.data, userEmail },
			})
			Cookies.set('accessToken', response.data?.AccessToken)
			Cookies.set('tokenType', response.data?.TokenType)
			Cookies.set('refreshToken', response.data?.RefreshToken)
			Cookies.set('idToken', response.data?.IdToken)
			Cookies.set('userRole', response.data?.userRole)
			Cookies.set('email', userEmail)
			Cookies.set('isTenantLogin', response.data?.isTenantLogin)
			Cookies.set('isAuthenticated', true)
			Cookies.set('firstName', response.data?.firstName)
			Cookies.set('lastName', response.data?.lastName)
			Cookies.set('MFAEnabled', false)
			Cookies.set('message', response.data?.message)
			toastAlert(response?.data?.message ? response?.data?.message : "Password changed successfully", 'success')
		})
		.catch((err) => {
			console.log(err, "errInEnforce");
			let error = err?.response?.data
			dispatch({
				type: LOGIN_FAIL,
				// payload : {error}
				payload: { userEmail, err, session }
			})
			toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.", 'error')
		})
}


export const handleLogout = (thisEvt) => async (dispatch) => {
	const { authData } = thisEvt.props;
	const url = configuration.signOut;
	thisEvt.setState({
		logoutLoading: true
	})
	const payload = {
		"access_token": authData?.accessToken || Cookies?.get("accessToken")
	}
	console.log(payload, "payload")
	await axios.post(url, payload, {
		headers: {
			"Authorization": `${authData?.tokenType} ${authData?.idToken}`
		}
	}).then((res) => {
		dispatch({
			type: LOGOUT_ACTION,
			payload: { tenantName: authData.tenantName, logo: authData.logo }
		})
		console.log(res.data);
		thisEvt.setState({ logoutLoading: false })
		thisEvt.handleClose();
		toastAlert(res.data?.message ? res.data?.message : "User signed out successfully", "success")
		thisEvt.deleteAllCookies();
		thisEvt.props.history.push('/login');
	})
		.catch((err) => {
			console.error(err, "logoutError")
			let errorMessage = err?.response?.data?.message;
			if (errorMessage === "Unauthorized") {
				thisEvt.deleteAllCookies();
				dispatch({
					type: LOGOUT_ACTION,
					payload: { tenantName: authData.tenantName, logo: authData.logo }
				})
				thisEvt.props.history.push('/login');
			}
			thisEvt.setState({ logoutLoading: false })
			thisEvt.handleClose();
			toastAlert(errorMessage ? errorMessage : "Something went wrong. Please try again later.", "error")
		});
}

export const handleAuthTokenError = (props) => async (dispatch) => {
	document.cookie.split(";").forEach((c) => {
		console.log("newCookieClearFunction")
		document.cookie = c
			.replace(/^ +/, "")
			.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
	});
	dispatch({
		type: LOGOUT_ACTION,
		payload: { tenantName: props.authData.tenantName, logo: props.authData.logo }
	})
	props?.history?.push("/login")
}